import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="mb-3 font-lato text-sm text-gray-500 dark:text-gray-300">
        Guess our wedding date in 6 tries!
      </p>
      <p className="font-lato text-sm text-gray-500 dark:text-gray-300">
        After each guess, the color of the tiles will change to show how close
        your guess was to our date!
      </p>
      <p className="mb-3 font-lato text-sm text-gray-500 dark:text-gray-300">
        The date is in the format MM-DD-YY.
      </p>
      <p className="font-lato text-sm text-gray-500 dark:text-gray-300">
        Examples:
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="0" isCompleted={true} />
        <Cell value="1" isCompleted={true} />
        <div className="dash flex items-center p-3 dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
          </svg>
        </div>
        <Cell value="1" isCompleted={true} />
        <Cell value="3" isCompleted={true} />
        <div className="dash flex items-center p-3 font-lato dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
          </svg>
        </div>
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="2"
          status="correct"
        />
        <Cell value="1" isCompleted={true} />
      </div>
      <p className="font-lato text-sm text-gray-500 dark:text-gray-300">
        The digit 2 is in the date and in the correct spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="0" isCompleted={true} />
        <Cell value="1" isCompleted={true} />
        <div className="dash flex items-center p-3 dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
          </svg>
        </div>
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="2"
          status="present"
        />
        <Cell value="3" isCompleted={true} />
        <div className="dash flex items-center p-3 dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
          </svg>
        </div>
        <Cell value="3" isCompleted={true} />
        <Cell value="0" isCompleted={true} />
      </div>
      <p className="font-lato text-sm text-gray-500 dark:text-gray-300">
        The digit 2 is in the date but in the wrong spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="0" isCompleted={true} />
        <Cell value="1" isCompleted={true} />
        <div className="dash flex items-center p-3 dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
          </svg>
        </div>
        <Cell value="1" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="2" status="absent" />
        <div className="dash flex items-center p-3 dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
          </svg>
        </div>
        <Cell value="3" isCompleted={true} />
        <Cell value="0" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The digit 2 is not in the date in any spot.
      </p>
      <div className="text-l m-4 rounded-lg bg-pink-200 py-4	font-lato-bolditalic text-gray-500 dark:bg-pink-800 dark:text-gray-300">
        <p>From programming partners to life partners,</p>
        <p>this game was developed by Apurva and Akhil!</p>
      </div>
      <button
        className="text-l rounded-lg border border-transparent bg-slate-200 p-4 text-center font-lato-bold text-sm font-medium shadow-sm hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-offset-2 active:bg-slate-400 dark:bg-slate-600 dark:text-white sm:text-base"
        onClick={() => handleClose()}
      >
        {' '}
        Play Now!
      </button>
    </BaseModal>
  )
}
